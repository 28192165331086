import * as React from 'react';
import './Settings.css';
import { AppStore } from '../../stores';
import { inject, observer } from 'mobx-react';
import AuthenticationService from '../../services/AuthenticationService';

export interface IProps {
  show: boolean;
  appStore?: AppStore;
}

export interface IState {
  showSettingsBackground: boolean;
}

@inject('appStore')
@observer
export default class Settings extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      showSettingsBackground: false
    }
  }

  componentWillReceiveProps(props: IProps): void {
    if (props.show && !this.props.show) {
      setTimeout(() => {
        this.setState({ showSettingsBackground: true });
      }, 0)
    };
  }

  render() {
    return (
      <>
        {this.props.appStore.showSettings &&
          <div
            className='settingsOverlay'
            style={{
              opacity: this.state.showSettingsBackground ? 0.6 : 0
            }}
          />
        }
        <div
          className='settings'
          style={{
            top: this.props.appStore.isMobile ? (this.props.appStore.showSettings ? 0 : screen.height) : 50,
            right: this.props.appStore.isMobile ? "unset" : (this.props.appStore.showSettings ? 0 : -500),
            height: this.props.appStore.isMobile ? "100%" : "calc(100% - 50px)",
            overflow: "hidden"
          }}
        >
          <div
            className='closeButton'
            onClick={() => {
              this.props.appStore.showSettings = false;
            }}
          />
          <div className="headline">{window.loc.strings.settings}</div>
          <div className='areaHeadline'>{window.loc.strings.profileSettings.toUpperCase()}</div>
          {this.props.appStore.user ?
            <div>
              <div className='loggedInAs'>{`${window.loc.strings.loggedInAs} ${this.props.appStore.user?.username}`}</div>
              <div
                className='settingsButton'
                onClick={() => {
                  this.props.appStore.showSettings = false;
                  this.props.appStore.logOut();
                }}
              >
                <div
                  className='settingsButtonIcon'
                  style={{
                    backgroundImage: 'url("https://dojo.mindjuice.com/images/logout-icon.png")'
                  }}
                />
                <div className='settingsButtonText' >
                  {window.loc.strings.logOut}
                </div>
              </div>
            </div>
            :
            <div>
              <div
                className='settingsButton'
                onClick={() => {
                  this.props.appStore.showWelcomepage = true;
                }}
              >
                <div
                  className='settingsButtonIcon'
                  style={{
                    backgroundImage: 'url("https://dojo.mindjuice.com/images/reset-password.png")'
                  }}
                />
                <div className='settingsButtonText'>{window.loc.strings.logIn}</div>
              </div>
            </div>
          }
          {this.props.appStore.user &&
            <div
              className='settingsButton'
              onClick={() => {
                this.props.appStore.showDeleteAccountPopup();
              }}
            >
              <div
                className='settingsButtonIcon'
                style={{
                  backgroundImage: 'url("https://dojo.mindjuice.com/images/delete-account.png")'
                }}
              />
              <div className='settingsButtonText'>
                {window.loc.strings.deleteAccount}
              </div>
            </div>
          }
          {this.props.appStore.user &&
            <div
              className='settingsButton'
              onClick={() => {
                this.props.appStore.showingUpdatePassword = true;
              }}
            >
              <div
                className='settingsButtonIcon'
                style={{
                  backgroundImage: 'url("https://dojo.mindjuice.com/images/reset-password.png")'
                }}
              />
              <div className='settingsButtonText'>{window.loc.strings.updatePassword}</div>
            </div>
          }
          <div
            className='areaHeadline'
            style={{
              marginTop: 40
            }}
          >
            {window.loc.strings.languageSettings.toUpperCase()}
          </div>
          <div
            className='languageOption'
            style={{
              backgroundImage: 'url("https://dojo.mindjuice.com/images/language-da.png")',
              border: localStorage.getItem("language") === "Danish" ? "3px solid #ffffff" : "3px solid transparent"
            }}
            onClick={() => {
              localStorage.setItem("language", "Danish");
              this.props.appStore.showSettings = false;
              setTimeout(() => {
                location.reload();
              }, 500);
            }}
          />
          <div
            className='languageOption'
            style={{
              backgroundImage: 'url("https://dojo.mindjuice.com/images/language-en.png")',
              border: localStorage.getItem("language") === "Danish" ? "3px solid transparent" : "3px solid #ffffff"
            }}
            onClick={() => {
              localStorage.setItem("language", "English");
              this.props.appStore.showSettings = false;
              setTimeout(() => {
                location.reload();
              }, 500);
            }}
          />

          <div
            className='areaHeadline'
            style={{
              marginTop: 40
            }}
          >
            {window.loc.strings.downloadMobileApp.toUpperCase()}
          </div>
          <a href="https://apps.apple.com/us/app/mindjuice/id1574566746" target="_blank">
            <div
              className='downloadAppButton'
              style={{
                backgroundImage: 'url("https://dojo.mindjuice.com/images/download-appstore.png")'
              }}
            />
          </a>
          <a href="https://play.google.com/store/apps/details?id=dk.mindjuice.dojo" target="_blank">
            <div
              className='downloadAppButton'
              style={{
                backgroundImage: 'url("https://dojo.mindjuice.com/images/download-googleplay.png")'
              }}
            />
          </a>

          <div
            className='areaHeadline'
            style={{
              marginTop: 40
            }}
          >
            {window.loc.strings.links.toUpperCase()}
          </div>
          <a href="https://www.mindjuice.com/" target="_blank">
            <div
              className='settingsButton'
            >
              <div
                className='settingsButtonIcon'
                style={{
                  backgroundImage: 'url("https://dojo.mindjuice.com/images/logout-icon.png")'
                }}
              />
              <div className='settingsButtonText' >
                {window.loc.strings.homepage}
              </div>
            </div>
          </a>
          <a href={window.loc.strings.language === "Danish" ? "https://mindjuice.com/dojo-faq/" : "https://mindjuice.com/dojo-faq/"} target="_blank">
            <div
              className='settingsButton'
            >
              <div
                className='settingsButtonIcon'
                style={{
                  backgroundImage: 'url("https://dojo.mindjuice.com/images/delete-account.png")'
                }}
              />
              <div className='settingsButtonText'>
                {window.loc.strings.faq}
              </div>
            </div>
          </a>

        </div>
      </>
    );
  }

}