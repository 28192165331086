import * as React from 'react';
import './Header.css';
import { AppStore } from '../../stores';
import { inject, observer } from 'mobx-react';

export interface IProps {
  headline: string;
  button?: any;
  appStore?: AppStore;
}

export interface IState {
}

@inject('appStore')
@observer
export default class Header extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <div className='header'>
        <div
          className="headline"
          style={{
            width: this.props.button || !this.props.appStore.user ? "calc(100% - 200px)" : "100%"
          }}
        >
          {this.props.headline}
        </div>
        {this.props.button &&
          <div
            className="headerAddButton"
            onClick={() => this.props.button.onClick()}
          >
            {this.props.button.title}
          </div>
        }
        {!this.props.appStore.user &&
          <div
            className="headerAddButton"
            onClick={() => this.props.appStore.showWelcomepage = true}
          >
            {window.loc.strings.logIn}
          </div>
        }
      </div>
    );
  }

}