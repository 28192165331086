import * as React from 'react';
import './Login.css';
import { AppStore } from '../../../stores';
import { inject, observer } from 'mobx-react';
import AuthenticationService from '../../../services/AuthenticationService';
import { IUser } from '../../../interfaces/IUser';
import * as ReactGA from 'react-ga';

export interface IProps {
  appStore?: AppStore;
  buttonColor?: string;
}

export interface IState {
  username: string;
  password: string;
  loginEnabled: boolean;
  loginError: boolean;
  showPassword: boolean;
}

@inject('appStore')
@observer
export default class Login extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      username: undefined,
      password: undefined,
      loginEnabled: false,
      loginError: false,
      showPassword: false
    }
    document.addEventListener("keyup", ((event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
        if (this.props.appStore.showingLogin) {
          this.logIn();
        }
      }
    }));
  }

  private validate(): void {
    if (this.state.username && this.state.password) {
      this.setState({ loginEnabled: true });
    } else {
      this.setState({ loginEnabled: false });
    }
  }

  private logIn(): void {
    if (this.state) {
      this.props.appStore.loggingIn = true;
      AuthenticationService.login(this.state.username, this.state.password).then((user: IUser) => {
        if (user) {
          ReactGA.event({
            category: 'User',
            action: `User logged in`
          });
          this.props.appStore.showWelcomepage = false;
          this.props.appStore.setUser(user);
        } else {
          this.setState({ loginError: true });
          setTimeout(() => {
            this.setState({ loginError: false });
          }, 1000);
        }
        this.props.appStore.loggingIn = false;
        if (location.href.includes("podcast=")) {
          location.reload();
        }
      });
    }
  }

  render() {
    return (
      <div
        className="login"
        style={{
          animation: this.state.loginError ? "shake 0.82s cubic-bezier(.36,.07,.19,.97) both" : "none",
          transform: "translate3d(0, 0, 0)",
          backfaceVisibility: "hidden",
          perspective: "1000px",
        }}
      >
        <input
          type="email"
          placeholder={window.loc.strings.email}
          onPaste={() => {
            setTimeout(() => {
              this.validate();
            }, 100);
          }}
          onChange={(event: any) => {
            this.setState({ username: event.target.value.toLowerCase() });
            this.validate();
          }}
          autoFocus
        />
        <input
          type={this.state.showPassword ? "text" : "password"}
          placeholder={window.loc.strings.password}
          onPaste={() => {
            setTimeout(() => {
              this.validate();
            }, 100);
          }}
          onChange={(event: any) => {
            this.setState({ password: event.target.value });
            this.validate();
          }}
        />
        <div
          className='showPasswordButton'
          style={{
            backgroundImage: this.state.showPassword ? 'url("https://img.icons8.com/fluency-systems-regular/48/invisible.png")' : 'url("https://img.icons8.com/fluency-systems-regular/48/000000/visible--v1.png")'
          }}
          onClick={() => this.setState({ showPassword: !this.state.showPassword })}
        />
        {this.props.appStore.loggingIn ?
          <div /> // Show spinner
          :
          <div
            className="loginBtn"
            style={{
              opacity: this.state.loginEnabled ? 1 : 0.5,
              cursor: this.state.loginEnabled ? "pointer" : "default",
            }}
            onClick={() => {
              if (this.state.loginEnabled) {
                this.logIn();
              }
            }}
          >
            {window.loc.strings.logIn?.toUpperCase()}
          </div>
        }
        <div
          className="forgottonPasswordBtn"
          style={{
            color: "#ffffff"
          }}
          onClick={() => {
            this.props.appStore.showForgottenPassword();
          }}
        >
          {window.loc.strings.forgottenPassword}
        </div>
      </div>
    );
  }

}