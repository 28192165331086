import * as React from 'react';
import './App.css';
import { AppStore } from './stores';
import { inject, observer } from 'mobx-react';
import { LocalizationService } from './services/LocalizationService';
import * as ReactGA from 'react-ga';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import NativeService from './services/NativeService';
import TabBar from './mindjuiceApp/tabBar/TabBar';
import Podcasts from './mindjuiceApp/podcasts/Podcasts';
import Meditations from './mindjuiceApp/meditations/Meditations';
import Tests from './mindjuiceApp/tests/Tests';
import Home from './mindjuiceApp/home/Home';
import MindPaper from './mindjuiceApp/mindPaper/MindPaper';
import PodcastPlayer from './mindjuiceApp/podcasts/podcastPlayer/PodcastPlayer';
import Profile from './mindjuiceApp/profile/Profile';
import Frontpage from './mindjuiceApp/landingPage/LandingPage';
import DeleteAccountPopup from './mindjuiceApp/deleteAccountPopup/DeleteAccountPopup';
import ChangeLanguagePopup from './mindjuiceApp/changeLanguagePopup/ChangeLanguagePopup';
import SideBar from './mindjuiceApp/sideBar/SideBar';
import Program from './mindjuiceApp/home/program/Program';
import SharePopup from './mindjuiceApp/sharePopup/SharePopup';
import Notifications from './mindjuiceApp/notifications/Notifications';
import EnneagrammyApp from './enneagrammyApp/Enneagrammy';
import EnneagrammyInfoPage from './mindjuiceApp/tests/enneagrammy/enneagrammyInfoPage/EnneagrammyInfoPage';
import EnneagrammyTest from './mindjuiceApp/tests/enneagrammy/enneagrammyTest/EnneagrammyTest';
import EnneagrammyResults from './mindjuiceApp/tests/enneagrammy/enneagrammyResults/EnneagrammyResults';
import NotificationService from './services/NotificationService';
import { IProduct } from './interfaces/IProduct';
import LogoBar from './mindjuiceApp/logoBar/LogoBar';
import ProductAccessPanel from './admin/products/productAccessPanel/ProductAccessPanel';
import TeamPanel from './admin/products/teamPanel/TeamPanel';
import TeamAccessPanel from './admin/products/teamPanel/teamAccessPanel/TeamAccessPanel';
import ProductEditPanel from './admin/products/productEditPanel/ProductEditPanel';
import MeditationEditPanel from './admin/meditations/meditationsEditPanel/MeditationsEditPanel';
import Users from './mindjuiceApp/users/Users';
import EnneagrammyLogin from './enneagrammyApp/login/EnneagrammyLogin';
import PodcastEditPanel from './admin/podcasts/podcastEditPanel/PodcastEditPanel';
import NotificationCenter from './mindjuiceApp/notificationCenter/NotificationCenter';
import NotificationEditPanel from './admin/notifications/meditationsEditPanel/NotificationEditPanel';
import Settings from './mindjuiceApp/settings/Settings';
import Diploma from './mindjuiceApp/diploma/Diploma';
import Mindpapers from './mindjuiceApp/mindpapers/Mindpapers';
import Notes from './mindjuiceApp/notes/Notes';
import PushService from './services/PushService';
import ProductService from './services/ProductService';
import { IProductAccess } from './interfaces/IProductAccess';
import PushPopup from './mindjuiceApp/pushPopup/PushPopup';
import UpdatePasswordPopup from './mindjuiceApp/updatePasswordPopup/UpdatePasswordPopup';
import AdminUpdatePasswordPopup from './mindjuiceApp/adminUpdatePasswordPopup/AdminUpdatePasswordPopup';
import NoAccess from './mindjuiceApp/noAccess/NoAccess';
import AdminDeleteAccountPopup from './mindjuiceApp/adminDeleteAccountPopup/AdminDeleteAccountPopup';
import AuthenticationService from './services/AuthenticationService';
import { IUser } from './interfaces/IUser';
import UpdatePasswordWrongPasswordPopup from './mindjuiceApp/updatePasswordPopup/updatePasswordWrongPasswordPopup/UpdatePasswordWrongPasswordPopup';
import ForgottenPasswordConfirmation from './mindjuiceApp/landingPage/forgottenPasswordConfirmation/ForgottenPasswordConfirmation';
import ProductOrderPopup from './admin/products/productOrderPanel/productOrderPopup/ProductOrderPopup';
import EventOrderPopup from './admin/products/eventOrderPanel/eventOrderPopup/EventOrderPopup';
import GroupsPanel from './admin/tests/groupsPanel/GroupsPanel';

export interface IProps {
  appStore?: AppStore;
}

export interface IState {
  showPageEmbed: boolean;
}

@inject('appStore')
@observer
export default class App extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      showPageEmbed: false
    }
    try {
      if (localStorage.getItem("user")) {
        this.props.appStore.setUser(JSON.parse(localStorage.getItem("user")));
      }
      if (window.loc == undefined) {
        window.loc = new LocalizationService();
      }
    } catch (error) {
      alert("The Mindjuice Dojo bruger cookies. Du skal tillade cookies i browserens indstillinger for komme videre.");
    }
    ReactGA.initialize('UA-78675020-1');
    ReactGA.pageview(window.location.pathname);
    const isIpad = (navigator.userAgent?.toLowerCase().includes("applewebkit") && !navigator.userAgent?.toLowerCase().includes("mobile")) || navigator.userAgent?.toLowerCase().includes("ipad");
    if ((this.props.appStore.isMobile == undefined || !this.props.appStore.isMobile) && !isIpad) {
      this.props.appStore.isMobile = this.isMobile();
    }
    this.props.appStore.updateBackground();
    window.addEventListener('popstate', (event) => {
      this.props.appStore.updateBackground();
      this.props.appStore.popup = undefined;
      this.props.appStore.pageEmbed = undefined;
    }, false);
    this.detectPlatform();
    this.getTabBarIndex();
    this.disableRightClick();
    window.addEventListener('popstate', () => {
      console.log(location.href);
      if (this.props.appStore.selectedProductId) {
        if (!location.href.includes(this.props.appStore.selectedProductId)) {
          location.reload();
        }
      }
      if (this.props.appStore.podcastToPlay) {
        if (!location.href.includes(this.props.appStore.podcastToPlay?.objectId)) {
          this.props.appStore.podcastToPlay = undefined;
        }
      }
    }, false);
    if (location.href.indexOf("mindPaper?mindPaperId") == -1) {
      if (this.props.appStore.user) {
        this.props.appStore.getNotifications();
      }
    }
    if (!localStorage.getItem("introPopup")) {
      this.props.appStore.showingIntroPopup = true;
    }
    if (localStorage.getItem("editMode")) {
      this.props.appStore.editMode = true;
    }
    if (this.props.appStore.user?.userType === "admin" && !this.props.appStore.user?.masterKey) {
      AuthenticationService.getUser(this.props.appStore.user).then((user: IUser) => {
        if (user) {
          this.props.appStore.setUser(user);
        }
      })
    }
  }

  public componentDidMount(): void {
    setTimeout(() => {
      NativeService.ready();
    }, 500);
    if (location.href.includes("/programs/")) {
      const splittedUrl = location.href.split("/programs/");
      if (splittedUrl?.length > 1) {
        this.props.appStore.selectedProductId = splittedUrl[1];
      }
    }
    if (location.href.includes("/home/")) {
      const splittedUrl = location.href.split("/home/");
      if (splittedUrl?.length > 1) {
        this.props.appStore.selectedProductId = splittedUrl[1];
      }
    }
    if (location.href.includes("/enneagrammy") || location.href.includes("/typetest")) {
      this.props.appStore.isUniversityApp = false;
    }

    if (this.props.appStore.user) {
      if (this.props.appStore.products && this.props.appStore.products.length > 0) {
        this.forceUpdate();
      } else {
        this.props.appStore.getProducts().then(() => {
          this.forceUpdate();
        });
      }
    }
  }

  private detectPlatform(): void {
    if (navigator.userAgent.toLowerCase().indexOf("android") > -1 && window.android != undefined) {
      window.platform = "android";
    } else if (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform) && window.webkit != undefined) {
      window.platform = "ios";
    } else {
      window.platform = "web";
    }
    console.log("Platform: " + window.platform);
  }

  private isMobile(): boolean {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/iPhone|iPod/i) ||
      navigator.userAgent.match(/Opera Mini/i) ||
      navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i) ||
      location.href.indexOf("ismobile") != -1 ||
      localStorage.getItem("isMobileOrTablet") != undefined ||
      localStorage.getItem("ismobile") != undefined
    ) {
      if (location.href.indexOf("isMobile") != -1 || location.href.indexOf("typetest") != -1) {
        localStorage.setItem("isMobile", "true");
      }
      return true;
    }
  }

  private isTablet(): boolean {
    if (
      navigator.userAgent.match(/iPad/i) ||
      navigator.platform.indexOf("iPad") !== -1
    ) {
      if (location.href.indexOf("isTablet") != -1) {
        localStorage.setItem("isTablet", "true");
      }
      return true;
    }
    return false;
  }

  private disableRightClick(): void {
    document.addEventListener("contextmenu", function (e) {
      e.preventDefault();
    });
  }

  private getTabBarIndex(): void {
    let pathname = location.pathname?.split("/");
    if (pathname.length > 1) {
      switch (pathname[1]) {
        case "":
          this.props.appStore.selectedTab = 1;
          break;
        case "tests":
          this.props.appStore.selectedTab = 2;
          break;
        case "inspiration":
          this.props.appStore.selectedTab = 3;
          break;
        case "meditations":
          this.props.appStore.selectedTab = 4;
          break;
        case "mindpapers":
          this.props.appStore.selectedTab = 5;
          break;
        case "users":
          this.props.appStore.selectedTab = 6;
          break;
        case "notifications":
          this.props.appStore.selectedTab = 7;
          break;
        default:
          break;
      }
    } else {
      this.props.appStore.selectedTab = 0;
    }
  }

  private getPushReceivers(): Promise<string[]> {
    return new Promise(async (resolve) => {
      let pushReceivers: string[] = [];
      for (const product of this.props.appStore.notificationToEdit.receivers) {
        if (product.selected) {
          // Get users with access to product
          await ProductService.getUsersWithAccessToProduct(product.product.productId).then((productAccesses: IProductAccess[]) => {
            if (productAccesses?.length > 0) {
              for (const productAccess of productAccesses) {
                if (productAccess?.user) {
                  pushReceivers.push(productAccess?.user);
                }
              }
            }
          });
        } else {
          if (product.teams?.length > 0) {
            await ProductService.getUsersWithAccessToProduct(product.product.productId).then((productAccesses: IProductAccess[]) => {
              for (const team of product.teams) {
                if (team.selected) {
                  if (productAccesses?.length > 0) {
                    productAccesses.forEach((userWithAccess: IProductAccess) => {
                      if (team.team === userWithAccess.team) {
                        if (userWithAccess?.user) {
                          pushReceivers.push(userWithAccess?.user);
                        }
                      }
                    });
                  }
                }
              }
            });
          }
        }
      }
      pushReceivers = [...new Set(pushReceivers)];
      console.log(pushReceivers);
      resolve(pushReceivers);
    });
  }

  render() {
    if (this.props.appStore.pageEmbed !== undefined && !this.state.showPageEmbed) {
      setTimeout(() => {
        this.setState({ showPageEmbed: true });
      }, 200);
    }
    let productId = "";
    if (!this.props.appStore.podcastToPlay) {
      if (this.props.appStore.selectedProductId == undefined && location.href?.split("/programs/")?.length > 1) {
        productId = location.href?.split("/programs/")[1];
        if (productId.includes("?")) {
          this.props.appStore.selectedProductId = productId.split("?")[0];
        } else {
          this.props.appStore.selectedProductId = productId;
        }
      }
      if (this.props.appStore.selectedProductId == undefined && location.href?.split("/home/")?.length > 1) {
        productId = location.href?.split("/home/")[1];
        if (productId.includes("?")) {
          this.props.appStore.selectedProductId = productId.split("?")[0];
        } else {
          this.props.appStore.selectedProductId = productId;
        }
      }
    }
    return (location.pathname.indexOf("diplom") != -1 && this.props.appStore.user != undefined) ? (
      <Diploma />
    )
      :
      (
        <Router>
          <div>
            <div
              id="mindjuice"
              className="mindjuice"
              style={{
                overflow: !this.props.appStore.printMode ? "hidden" : "unset"
              }}
            >
              {location.href.indexOf("mindPaperId") == -1 && !this.props.appStore.isMobile && !window.location.pathname.includes("/enneagrammy") && !window.location.pathname.includes("/typetest") &&
                <LogoBar />
              }
              {location.href.indexOf("mindPaperId") == -1 && !this.props.appStore.isMobile && !window.location.pathname.includes("/enneagrammy") && !window.location.pathname.includes("/typetest") &&
                <SideBar />
              }
              {!this.props.appStore.loading && (!window.location.pathname.includes("/enneagrammy") || (window.location.pathname.includes("/enneagrammy") && this.props.appStore.user)) ?
                <Switch>
                  <Route exact path={`${this.props.appStore.environment}/`}>
                    <Home />
                  </Route>
                  <Route path={`${this.props.appStore.environment}/tests`}>
                    <Tests />
                  </Route>
                  <Route path={`${this.props.appStore.environment}/inspiration`}>
                    <Podcasts />
                  </Route>
                  <Route path={`${this.props.appStore.environment}/meditations`}>
                    <Meditations />
                  </Route>
                  <Route path={`${this.props.appStore.environment}/users`}>
                    {this.props.appStore.user?.userType === "admin" ?
                      <Users />
                      :
                      <NoAccess />
                    }
                  </Route>
                  <Route path={`${this.props.appStore.environment}/notifications`}>
                    {this.props.appStore.user?.userType === "admin" ?
                      <NotificationCenter />
                      :
                      <NoAccess />
                    }
                  </Route>
                  <Route path={`${this.props.appStore.environment}/mindPaper2`}>
                    <MindPaper />
                  </Route>
                  <Route path={`${this.props.appStore.environment}/mindPapers`}>
                    {this.props.appStore.user?.userType === "admin" ?
                      <Mindpapers />
                      :
                      <NoAccess />
                    }
                  </Route>
                  <Route path={`${this.props.appStore.environment}/notes`}>
                    <Notes />
                  </Route>
                  <Route path={`${this.props.appStore.environment}/enneagrammy`}>
                    <EnneagrammyApp />
                  </Route>
                  <Route path={`${this.props.appStore.environment}/typetest`}>
                    <EnneagrammyApp />
                  </Route>
                </Switch>
                :
                <Switch>
                  <Route path={`${this.props.appStore.environment}/typetest`}>
                    <EnneagrammyLogin />
                  </Route>
                  <Route path={`${this.props.appStore.environment}/enneagrammy`}>
                    <EnneagrammyLogin />
                  </Route>
                  <Route path={`${this.props.appStore.environment}/`}>
                    <Frontpage />
                  </Route>
                </Switch>
              }
              <Profile
                show={this.props.appStore.showProfile}
              />
              <Settings
                show={this.props.appStore.showSettings}
              />
              <Notifications
                show={this.props.appStore.showNotification}
              />
              {this.props.appStore.showingDeleteAccount &&
                <DeleteAccountPopup />
              }
              {this.props.appStore.showingUpdatePassword &&
                <UpdatePasswordPopup />
              }
              {this.props.appStore.showingUpdatePasswordWrongPasswordPopup &&
                <UpdatePasswordWrongPasswordPopup />
              }
              {this.props.appStore.showingForgottenPasswordConfirmation &&
                <ForgottenPasswordConfirmation />
              }
              {this.props.appStore.showingAdminUpdatePasswordForUser &&
                <AdminUpdatePasswordPopup />
              }
              {this.props.appStore.showChangeLanguagePopup &&
                <ChangeLanguagePopup />
              }
              {this.props.appStore.shareContent &&
                <SharePopup />
              }
              {this.props.appStore.showingProductOrderPopup &&
                <ProductOrderPopup />
              }
              {this.props.appStore.showingEventOrderPopup &&
                <EventOrderPopup />
              }
              {/* {this.props.appStore.showingIntroPopup && !window.location.pathname.includes("/typetest") && !window.location.pathname.includes("/enneagrammy") && this.props.appStore.user &&
              <IntroPopup />
            } */}
              {this.props.appStore.pushToShow &&
                <PushPopup
                  close={() => {
                    this.props.appStore.getNotifications();
                  }}
                />
              }
              <PodcastPlayer
                podcastId={this.props.appStore.podcastToPlay?.objectId}
                closePlayer={() => {
                  this.props.appStore.podcastToPlay = undefined;
                  this.props.appStore.podcastIsPlaying = false;
                  window.history.replaceState("", "", `${location.origin}${location.pathname}`);
                }}
              />
              {location.href.indexOf("mindPaperId") == -1 && this.props.appStore.user && this.props.appStore.isMobile && !window.location.pathname.includes("/enneagrammy") && !window.location.pathname.includes("/typetest") &&
                <TabBar />
              }
              <Program
                id={this.props.appStore.selectedProductId}
                close={() => {
                  this.props.appStore.selectedProductId = "";
                  this.forceUpdate();
                }}
              />
              <EnneagrammyInfoPage
                open={this.props.appStore.showEnneagrammy != undefined}
                close={() => { }}
              />
              <EnneagrammyTest
                close={() => { }}
              />
              <EnneagrammyResults
                show={this.props.appStore.showEnneagrammyTestResults}
              />
              {this.props.appStore.showWelcomepage &&
                <Frontpage />
              }
            </div>
            {this.props.appStore.popup !== undefined &&
              <div className="mindjuicePdfViewer">
                <Link
                  to={`${this.props.appStore.environment}${location.pathname}`}
                >
                  <div
                    className='closeButton'
                    onClick={() => {
                      this.props.appStore.popup = undefined;
                    }}
                  />
                </Link>
                <iframe
                  src={this.props.appStore.popup}
                  width={"80%"}
                  height={window.innerHeight - 60}
                  style={{
                    border: "none",
                    position: "relative",
                    marginTop: 0,
                    marginLeft: "10%",
                    top: window.platform === "ios" ? 0 : 0,
                    height: window.platform === "ios" ? "calc(100vh - 0px)" : "calc(100vh - 0px)",
                  }}
                  allowFullScreen
                >
                </iframe>
              </div>
            }
            <div
              className="mindjuicePdfViewer"
              style={{
                top: this.props.appStore.pageEmbed !== undefined ? (this.props.appStore.isMobile ? 0 : 50) : screen.height,
                height: this.props.appStore.isMobile ? "100%" : "calc(100% - 50px)",
                overflow: "hidden",
                position: "fixed"
              }}
            >
              <div
                className='closeButton'
                onClick={() => {
                  this.props.appStore.pageEmbed = undefined;
                  this.setState({ showPageEmbed: false });
                  if (this.props.appStore.isSliderEvent) {
                    history.back();
                    this.props.appStore.isSliderEvent = false;
                  }
                }}
              />
              {this.props.appStore.loadingEmbeddedPage &&
                <div className='loadingScreen'>
                  <div className="spinner">
                    <div className="spinnerLabel">{window.loc.strings.loading}</div>
                  </div>
                </div>
              }
              {this.state.showPageEmbed &&
                <iframe
                  src={this.props.appStore.pageEmbed}
                  width={"100%"}
                  height={"100%"}
                  style={{
                    border: "none",
                    marginTop: 0,
                    marginLeft: 0
                  }}
                  allowFullScreen
                >
                </iframe>
              }
            </div>
          </div>
          <ProductAccessPanel
            productId={this.props.appStore.showProductAccessPanelForProduct}
          />
          <TeamPanel
            productId={this.props.appStore.showTeamPanelForProduct}
          />
          <TeamAccessPanel
            productId={this.props.appStore.showTeamAccessPanelForProductAndTeam ? this.props.appStore.showTeamAccessPanelForProductAndTeam.productId : undefined}
            teamId={this.props.appStore.showTeamAccessPanelForProductAndTeam ? this.props.appStore.showTeamAccessPanelForProductAndTeam.teamId : undefined}
          />
          <ProductEditPanel
            update={() => {
              this.props.appStore.getProducts().then(() => {
                this.forceUpdate();
              });
            }}
          />
          <MeditationEditPanel
            update={() => this.forceUpdate()}
          />
          <PodcastEditPanel
            update={() => this.forceUpdate()}
          />
          <NotificationEditPanel
            id={this.props.appStore.notificationToEdit && this.props.appStore.notificationToEdit.objectId}
            save={async () => {
              if (this.props.appStore.notificationToEdit.sendToAll && (this.props.appStore.notificationToEdit.pushReceivers == undefined || this.props.appStore.notificationToEdit.pushReceivers?.length === 0)) {
                if (this.props.appStore.notificationToEdit.objectId && this.props.appStore.notificationToEdit.objectId !== "new") {
                  NotificationService.updateNotification(this.props.appStore.notificationToEdit).then(() => {
                    this.props.appStore.notificationToEdit = undefined;
                  });
                } else {
                  NotificationService.createNotification(this.props.appStore.notificationToEdit).then(() => {
                    // Send push
                    PushService.sendPush({
                      "app_id": "928ff6d5-5b5f-437b-a4c8-4a6dfcdb35e2",
                      "included_segments": [
                        "Subscribed Users"
                      ],
                      "content_available": 1,
                      "headings": {
                        "en": this.props.appStore.notificationToEdit.title
                      },
                      "contents": {
                        "en": this.props.appStore.notificationToEdit.text
                      },
                      "name": "INTERNAL_CAMPAIGN_NAME",
                      "data": {
                        "url": this.props.appStore.notificationToEdit.link,
                        "linkText": this.props.appStore.notificationToEdit.linkText
                      }
                    }).then(() => {
                      this.props.appStore.notificationToEdit = undefined;
                    });
                  });
                }
              } else if (this.props.appStore.productToNotify && this.props.appStore.productToNotify.length > 0 && (this.props.appStore.notificationToEdit.pushReceivers == undefined || this.props.appStore.notificationToEdit.pushReceivers?.length === 0)) {
                this.props.appStore.notificationToEdit.receivers = [];
                this.props.appStore.productToNotify.forEach((product: { product: IProduct, selected: boolean, teams: any[] }) => {
                  if (product.selected) {
                    this.props.appStore.notificationToEdit.receivers.push(
                      product
                    );
                  } else {
                    if (product.teams != undefined && product.teams.length > 0) {
                      let teamIsSelected = false;
                      product.teams.forEach((team: any) => {
                        if (team.selected) {
                          teamIsSelected = true;
                        }
                      });
                      if (teamIsSelected) {
                        this.props.appStore.notificationToEdit.receivers.push(
                          product
                        );
                      }
                    }
                  }
                });
                // Get push receivers
                let pushReceivers = [];
                if (this.props.appStore.notificationToEdit.receivers?.length > 0) {
                  pushReceivers = await this.getPushReceivers();
                  this.props.appStore.notificationToEdit.pushReceivers = pushReceivers;
                }
                if (this.props.appStore.notificationToEdit.objectId && this.props.appStore.notificationToEdit.objectId !== "new") {
                  NotificationService.updateNotification(this.props.appStore.notificationToEdit).then(() => {
                    this.props.appStore.notificationToEdit = undefined;
                  });
                } else {
                  NotificationService.createNotification(this.props.appStore.notificationToEdit).then(async () => {
                    // Send push
                    if (pushReceivers?.length > 0) {
                      PushService.sendPush({
                        "app_id": "928ff6d5-5b5f-437b-a4c8-4a6dfcdb35e2",
                        "include_external_user_ids": pushReceivers,
                        "content_available": 1,
                        "headings": {
                          "en": this.props.appStore.notificationToEdit.title
                        },
                        "contents": {
                          "en": this.props.appStore.notificationToEdit.text
                        },
                        "name": "INTERNAL_CAMPAIGN_NAME",
                        "data": {
                          "url": this.props.appStore.notificationToEdit.link,
                          "linkText": this.props.appStore.notificationToEdit.linkText
                        }
                      }).then(() => {
                        this.props.appStore.notificationToEdit = undefined;
                      });
                    }
                    this.props.appStore.notificationToEdit = undefined;
                  });
                }
              } else {
                // Get push receivers
                let pushReceivers = [];
                if (this.props.appStore.notificationToEdit.receivers?.length > 0) {
                  pushReceivers = await this.getPushReceivers();
                  this.props.appStore.notificationToEdit.pushReceivers = pushReceivers;
                }
                if (this.props.appStore.notificationToEdit.objectId && this.props.appStore.notificationToEdit.objectId !== "new") {
                  NotificationService.updateNotification(this.props.appStore.notificationToEdit).then(() => {
                    this.props.appStore.notificationToEdit = undefined;
                  });
                } else {
                  if (this.props.appStore.notificationToEdit.pushReceivers?.length > 0) {
                    NotificationService.createNotification(this.props.appStore.notificationToEdit).then(async () => {
                      // Send push
                      PushService.sendPush({
                        "app_id": "928ff6d5-5b5f-437b-a4c8-4a6dfcdb35e2",
                        "include_external_user_ids": this.props.appStore.notificationToEdit.pushReceivers,
                        "content_available": 1,
                        "headings": {
                          "en": this.props.appStore.notificationToEdit.title
                        },
                        "contents": {
                          "en": this.props.appStore.notificationToEdit.text
                        },
                        "name": "INTERNAL_CAMPAIGN_NAME",
                        "data": {
                          "url": this.props.appStore.notificationToEdit.link,
                          "linkText": this.props.appStore.notificationToEdit.linkText
                        }
                      }).then(() => {
                        this.props.appStore.notificationToEdit = undefined;
                      });
                      this.props.appStore.notificationToEdit = undefined;
                    });
                  }
                }
              }
            }
            }
            delete={() => {
              NotificationService.deleteNotification(this.props.appStore.notificationToEdit.objectId).then(() => {
                this.props.appStore.notificationToEdit = undefined;
              });
            }}
          />
          <GroupsPanel
            id={this.props.appStore.groupToEdit?.objectId}
            save={() => { }}
            delete={() => { }}
          />
        </Router>
      );
  } ƒ

  public reloadApp(): void {
    alert("Jonas");
  }

  public log(): void {
    NativeService.log("Logging from web app!")
  }

  public showPush(title: string, text: string, url?: string, linkText?: string): void {
    this.props.appStore.pushToShow = {
      title: title,
      text: text,
      url: url,
      linkText: linkText
    };
    this.props.appStore.getNotifications();
  }

  public subscribed(product: "enneagramapp.mindjuice.dk.monthlySubscription" | "enneagramapp.mindjuice.dk.yearlySubscription"): void {
    NativeService.log(product);
    this.props.appStore.isShowingSubscriptionPopup = false;
    localStorage.setItem("typeTestAccess", "hasAccess");
    this.props.appStore.isWaitingForApple = false;
    this.props.appStore.subscription = product;
    this.props.appStore.selectedSubscription = product;
    this.props.appStore.isRestoringSubscription = false;
    this.props.appStore.userStillHasAccess = true;
    this.props.appStore.checkAccessDone = true;
    this.forceUpdate();
    console.log("%cUser has access from Apple subscription", 'background: orange; color: #272727');
  }

  public notSubscribed(): void {
    NativeService.log("notSubscribed");
    localStorage.removeItem("typeTestAccess");
    this.props.appStore.isWaitingForApple = false;
    this.props.appStore.subscription = undefined;
    if (this.props.appStore.isRestoringSubscription) {
      alert(window.loc.strings.restoreFailed);
      this.props.appStore.isRestoringSubscription = false;
    }
    this.props.appStore.userStillHasAccess = false;
    this.props.appStore.checkAccessDone = true;
    this.forceUpdate();
  }

  public isHandlingATrsnsaction(result): void {
    if (result) {
      setTimeout(() => {
        NativeService.isHandlingATrsnsaction();
      }, 1000);
    } else {
      NativeService.isUserSubscribed();
      this.props.appStore.isWaitingForApple = false;
    }
  }

}