import * as React from 'react';
import './Program.css';
import { inject, observer } from 'mobx-react';
import { AppStore } from '../../../stores';
import ProductService from '../../../services/ProductService';
import { ITeam } from '../../../interfaces/ITeam';
import { Pivot } from '../../pivot/Pivot';
import { Accordion } from '../../accordion/Accordion';
import IconService from '../../../services/IconService';
import { IProductModule } from '../../../interfaces/IProductModule';
import { AccordionItem } from '../../accordion/accordionItem/AccordionItem';
import { Link } from 'react-router-dom';
import { IProductLink } from '../../../interfaces/IProductLink';
import PodcastList from '../../podcasts/podcastList/PodcastList';

export interface IProps {
  id: string;
  close: () => void;
  appStore?: AppStore;
}

export interface IState {
  hasProductAccess: boolean;
  selectedPivot: string;
  teams?: ITeam[];
}

@inject('appStore')
@observer
export default class Program extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      hasProductAccess: this.props.appStore.user?.username === "tester@mindjuice.dk",
      selectedPivot: "about"
    }
  }

  public componentDidMount(): void {
    this.props.appStore.selectedProductTeam = undefined;
    if (this.props.id) {
      if (this.props.appStore.products && this.props.appStore.products.length > 0) {
        this.getProduct(this.props.id);
      } else {
        this.props.appStore.getProducts().then(() => {
          this.getProduct(this.props.id);
        });
      }
    }
    if (location.href.includes("?mindPaper=")) {
      const mindPaperId = location.search.split("?mindPaper=")[1];
      this.setState({ selectedPivot: "content" });
      setTimeout(() => {
        this.props.appStore.pageEmbed = `https://dojo.mindjuice.com/mindPaper2?mindPaperId=${mindPaperId}`; // `http://localhost:3000/mindPaper2?mindPaperId=${mindPaperId}` 
      }, 1000);
    }
  }

  public componentWillReceiveProps(props: IProps): void {
    if (props.id !== this.props.id) {
      if (this.props.appStore.products && this.props.appStore.products.length > 0) {
        this.getProduct(props.id);
      } else {
        this.props.appStore.getProducts().then(() => {
          this.getProduct(props.id);
        });
      }
    }
  }

  private mapProduct(title: string): boolean {
    let hasAccess = false;
    switch (title) {
      case "Kender du typen?":
        if (this.props.appStore.selectedProduct?.productId === "17827") {
          hasAccess = true;
        }
        break;
      case "Instinkternes DNA":
        if (this.props.appStore.selectedProduct?.productId === "19996") {
          hasAccess = true;
        }
        break;
      case "Instinkternes DNA Enneagram kursus":
        if (this.props.appStore.selectedProduct?.productId === "19996") {
          hasAccess = true;
        }
        break;

      case "Din Indre Odyssé – Kursus i Instinkter":
        if (this.props.appStore.selectedProduct?.productId === "19996") {
          hasAccess = true;
        }
        break;
      default:
        break;
    }
    return hasAccess
  }

  private getProduct(id): void {
    if (this.props.appStore.products.length > 0) {
      this.props.appStore.selectedProduct = this.props.appStore.getProductFromId(id);
      if (this.props.appStore.editMode) {
        this.setState({ hasProductAccess: true });
        this.getTeams(id);
      } else {
        if (this.props.appStore.selectedProduct && this.props.appStore.selectedProductTeam == undefined && this.props.appStore.user?.username !== "tester@mindjuice.dk") {
          ProductService.productAccess(this.props.appStore.selectedProduct.productId, this.props.appStore.user?.username).then((productAccess: any) => {
            if (productAccess && productAccess.results[0]) {
              this.props.appStore.teamId = productAccess.results[0].team;
              if (this.props.appStore.teamId) {
                ProductService.getTeam(this.props.appStore.teamId).then((team: ITeam[]) => {
                  if (team && team[0]) {
                    team.forEach((teamResult: any) => {
                      if (teamResult.productId === id) {
                        this.props.appStore.selectedProductTeam = teamResult;
                        this.forceUpdate();
                      }
                    })
                  }
                })
              }
              this.setState({ hasProductAccess: true });
            } else {
              // User does not have access from MongoDB and will check Billwerk
              this.props.appStore.getCustomerHandle().then((customerHandle: string) => {
                if (customerHandle) {
                  ProductService.getCustomerInvoice(customerHandle).then((invoice) => {
                    if (invoice?.content?.length > 0 && invoice.content[0].order_lines?.length > 0) {
                      invoice.content[0].order_lines.forEach((orderLine) => {
                        this.setState({ hasProductAccess: this.mapProduct(orderLine?.ordertext) });
                      });
                    }
                  })
                } else {
                  this.setState({ hasProductAccess: false });
                }
              });
            }
          });
        }
      }
    }
  }

  private getTeams(productId: string): void {
    ProductService.getTeamsForProduct(productId).then((teams: ITeam[]) => {
      if (teams && teams.length > 0) {
        this.props.appStore.selectedProductTeam = teams[0];
        this.setState({ teams });
      }
    });
  }

  render() {
    let language = "danish";
    if (localStorage.getItem("language")) {
      language = localStorage.getItem("language").toLowerCase();
    }
    const informationDocuments = [];
    const productDocuments = [];
    const examDocuments = [];
    const videos = [];
    const teamOptions = [];
    if (this.state.teams) {
      this.state.teams.forEach((team: ITeam) => {
        teamOptions.push(
          <option value={team.objectId} selected={this.props.appStore.selectedProductTeam?.objectId === team.objectId}>{`Hold ${team.team}`}</option>
        )
      });
    }
    // Program modules
    if (this.state.hasProductAccess && this.props.appStore.selectedProduct && this.props.appStore.products.length > 0 && this.props.appStore.selectedProduct.modules && !this.props.appStore.selectedProductTeam) {
      this.props.appStore.selectedProduct.modules.forEach((module: IProductModule, moduleIndex) => {
        const moduleDocuments = [];
        const moduleCoverImage = language !== "danish" && module.localizedCoverImage && module.localizedCoverImage[language] ? module.localizedCoverImage[language] : (module.coverImage ? module.coverImage : "https://dojo.mindjuice.com/images/defaultCoverImage.jpg");
        const moduleTitle = language !== "danish" && module.localizedTitle && module.localizedTitle[language] ? module.localizedTitle[language] : module.title;
        const moduleVideoUrl = language !== "danish" && module.localizedVideoUrl && module.localizedVideoUrl[language] ? module.localizedVideoUrl[language] : module.videoUrl;
        if (module.documents && module.documents.length > 0) {
          module.documents.forEach((moduleDocumentId: string, index) => {
            if (moduleDocumentId) {
              moduleDocuments.push(
                <AccordionItem
                  key={moduleDocumentId}
                  item={{
                    id: moduleDocumentId
                  }}
                  isMobile={this.props.appStore.isMobile}
                  backgroundColor="#232323"
                  hideBorder={index === module.documents.length - 1}
                  openInPopup={(url: string) => {
                    this.props.appStore.popup = url;
                  }}
                  openInPageEmbed={(url: string) => {
                    this.props.appStore.pageEmbed = url;
                  }}
                />
              )
            }
          });
          if (module.moduleType === "info") {
            informationDocuments.push(
              {
                id: moduleIndex,
                title: moduleTitle,
                children: moduleDocuments
              }
            )
          } else if (module.moduleType === "exam") {
            examDocuments.push(
              {
                id: moduleIndex,
                title: moduleTitle,
                children: moduleDocuments
              }
            )
          } else {
            productDocuments.push(
              {
                id: moduleIndex,
                title: moduleTitle,
                children: moduleDocuments
              }
            )
          }
        }
        if (moduleVideoUrl || module.workbook) {
          videos.push(
            <div
              key={moduleTitle}
              className="moduleWrapper"
            >
              {moduleVideoUrl && moduleVideoUrl.substr(moduleVideoUrl.length - 3) === "mp3" ?
                <PodcastList
                  width={600}
                  marginRight={0}
                  title={moduleTitle}
                  speaker={"Mindjuice"}
                  image={moduleCoverImage}
                  selected={this.props.appStore.podcastToPlay?.title === moduleTitle}
                  onClick={() => this.props.appStore.podcastToPlay = {
                    link: moduleVideoUrl,
                    title: moduleTitle,
                    speaker: "Mindjuice",
                    image: moduleCoverImage
                  }}
                />
                :
                <>
                  <iframe
                    src={moduleVideoUrl}
                    width={window.innerWidth < 768 ? "100%" : "600"}
                    height={window.innerWidth < 768 ? (screen.availWidth - 40) / 16 * 9 : "338"}
                    allow="autoplay; fullscreen"
                    allowFullScreen
                  />
                  <div
                    className='videoOverlay'
                    style={{
                      marginTop: (window.innerWidth < 768 ? -(screen.availWidth - 40) / 16 * 9 : -338) + 10,
                    }}
                    onClick={() => {
                      this.props.appStore.podcastToPlay = {
                        videoUrl: moduleVideoUrl,
                        title: moduleTitle,
                        speaker: "Mindjuice",
                        image: "https://dojo.mindjuice.com/images/iTunesArtwork%401x.png"
                      }
                    }}
                  />
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      marginTop: 15,
                      marginBottom: 40
                    }}
                  >
                    {moduleTitle}
                  </div>
                </>
              }
            </div>
          );
        }
      });
    }

    // Team modules
    if (this.state.hasProductAccess && this.props.appStore.selectedProductTeam && this.props.appStore.selectedProductTeam.modules && this.props.appStore.selectedProductTeam.modules.length > 0) {
      this.props.appStore.selectedProductTeam.modules.forEach((module: IProductModule, moduleIndex) => {
        const moduleDocuments = [];
        const moduleCoverImage = language !== "danish" && module.localizedCoverImage && module.localizedCoverImage[language] ? module.localizedCoverImage[language] : (module.coverImage ? module.coverImage : "https://dojo.mindjuice.com/images/defaultCoverImage.jpg");
        const moduleTitle = language !== "danish" && module.localizedTitle && module.localizedTitle[language] ? module.localizedTitle[language] : module.title;
        const moduleVideoUrl = language !== "danish" && module.localizedVideoUrl && module.localizedVideoUrl[language] ? module.localizedVideoUrl[language] : module.videoUrl;
        if (module.documents && module.documents.length > 0) {
          module.documents.forEach((moduleDocumentId: string, index) => {
            if (moduleDocumentId) {
              moduleDocuments.push(
                <AccordionItem
                  key={moduleDocumentId}
                  item={{
                    id: moduleDocumentId
                  }}
                  isMobile={this.props.appStore.isMobile}
                  backgroundColor="#232323"
                  hideBorder={index === module.documents.length - 1}
                  openInPopup={(url: string) => {
                    this.props.appStore.popup = url;
                  }}
                  openInPageEmbed={(url: string) => {
                    this.props.appStore.pageEmbed = url;
                  }}
                />
              );
            }
          });
          if (module.moduleType === "info") {
            informationDocuments.push(
              {
                id: moduleIndex,
                title: moduleTitle,
                children: moduleDocuments
              }
            )
          } else if (module.moduleType === "exam") {
            examDocuments.push(
              {
                id: moduleIndex,
                title: moduleTitle,
                children: moduleDocuments
              }
            )
          } else {
            productDocuments.push(
              {
                id: moduleIndex,
                title: moduleTitle,
                children: moduleDocuments
              }
            )
          }
        }
        if (moduleVideoUrl || module.workbook) {
          videos.push(
            <div
              key={moduleTitle}
              className="moduleWrapper"
            >
              {moduleVideoUrl && moduleVideoUrl.substr(moduleVideoUrl.length - 3) === "mp3" ?
                <PodcastList
                  width={600}
                  marginRight={0}
                  title={moduleTitle}
                  speaker={"Mindjuice"}
                  image={moduleCoverImage}
                  selected={this.props.appStore.podcastToPlay?.title === moduleTitle}
                  onClick={() => this.props.appStore.podcastToPlay = {
                    link: moduleVideoUrl,
                    title: moduleTitle,
                    speaker: "Mindjuice",
                    image: moduleCoverImage
                  }}
                />
                :
                <>
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      marginTop: 25,
                      marginBottom: 20
                    }}
                    onClick={() => {
                      console.log(moduleTitle);
                      this.props.appStore.podcastToPlay = {
                        link: moduleVideoUrl,
                        title: moduleTitle
                      }
                    }}
                  >
                    {moduleTitle}
                  </div>
                  <iframe
                    src={moduleVideoUrl}
                    width={window.innerWidth < 768 ? "100%" : "600"}
                    height={window.innerWidth < 768 ? (screen.availWidth - 40) / 16 * 9 : "338"}
                    allow="autoplay; fullscreen"
                    allowFullScreen
                  />
                  <div
                    className='videoOverlay'
                    style={{
                      marginTop: (window.innerWidth < 768 ? -(screen.availWidth - 40) / 16 * 9 : -338) + 10,
                    }}
                    onClick={() => {
                      this.props.appStore.podcastToPlay = {
                        videoUrl: moduleVideoUrl,
                        title: moduleTitle,
                        speaker: "Mindjuice",
                        image: "https://dojo.mindjuice.com/images/iTunesArtwork%401x.png"
                      }
                    }}
                  />
                </>
              }
            </div>
          );
        }
      });
    }

    // No access
    if (!this.state.hasProductAccess && this.props.appStore.selectedProduct && this.props.appStore.products.length > 0 && this.props.appStore.selectedProduct.modules) {
      this.props.appStore.selectedProduct.modules.forEach((module: IProductModule, moduleIndex) => {
        const moduleDocuments = [];
        const moduleCoverImage = language !== "danish" && module.localizedCoverImage && module.localizedCoverImage[language] ? module.localizedCoverImage[language] : (module.coverImage ? module.coverImage : "https://dojo.mindjuice.com/images/defaultCoverImage.jpg");
        const moduleTitle = language !== "danish" && module.localizedTitle && module.localizedTitle[language] ? module.localizedTitle[language] : module.title;
        const moduleVideoUrl = language !== "danish" && module.localizedVideoUrl && module.localizedVideoUrl[language] ? module.localizedVideoUrl[language] : module.videoUrl;
        if (module.documents && module.documents.length > 0) {
          module.documents.forEach((moduleDocumentId: string, index) => {
            if (moduleDocumentId) {
              moduleDocuments.push(
                <AccordionItem
                  key={moduleDocumentId}
                  item={{
                    id: moduleDocumentId
                  }}
                  isMobile={this.props.appStore.isMobile}
                  backgroundColor="#232323"
                  preview
                  hideBorder={index === module.documents.length - 1}
                  openInPopup={(url: string) => {

                  }}
                  openInPageEmbed={(url: string) => {

                  }}
                />
              )
            }
          });
          if (module.moduleType === "info") {
            informationDocuments.push(
              {
                id: moduleIndex,
                title: moduleTitle,
                children: moduleDocuments
              }
            )
          } else if (module.moduleType === "exam") {
            examDocuments.push(
              {
                id: moduleIndex,
                title: moduleTitle,
                children: moduleDocuments
              }
            )
          } else {
            productDocuments.push(
              {
                id: moduleIndex,
                title: moduleTitle,
                children: moduleDocuments
              }
            )
          }
        }
        if (moduleVideoUrl || module.workbook || moduleCoverImage) {
          videos.push(
            <div
              key={moduleTitle}
              className="moduleWrapper"
            >
              {moduleVideoUrl && moduleVideoUrl.substr(moduleVideoUrl.length - 3) === "mp3" ?
                <PodcastList
                  width={600}
                  marginRight={0}
                  title={moduleTitle}
                  speaker={"Mindjuice"}
                  image={moduleCoverImage}
                  selected={this.props.appStore.podcastToPlay?.title === moduleTitle}
                  onClick={() => this.props.appStore.podcastToPlay = {
                  }}
                />
                :
                <>
                  <div
                    style={{
                      width: window.innerWidth < 768 ? "100%" : "600",
                      height: window.innerWidth < 768 ? (screen.availWidth - 40) / 16 * 9 : "338",
                      backgroundImage: `url(${moduleVideoUrl}`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      float: "left",
                      borderRadius: 10,
                      backgroundColor: "#272727"
                    }}
                  >
                    {moduleCoverImage &&
                      <div
                        style={{
                          width: window.innerWidth < 768 ? "100%" : "600",
                          height: window.innerWidth < 768 ? (screen.availWidth - 40) / 16 * 9 : "338",
                          backgroundImage: `url("https://dojo.mindjuice.com/images/video-placeholder.png")`,
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          float: "left",
                          borderRadius: 10,
                        }}
                      />
                    }
                  </div>

                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      marginTop: 15,
                      marginBottom: 40
                    }}
                  >
                    {moduleTitle}
                  </div>
                </>
              }
            </div>
          );
        }
      });
    }


    const programData = [];
    if (this.props.appStore.selectedProductTeam) {
      programData.push(
        {
          title: `${window.loc.strings.team} ${this.props.appStore.selectedProductTeam.team}`,
          icon: IconService.getIcon("People Audience", "#ffffff")
        }
      )
    }
    if (this.props.appStore.selectedProduct?.numberOfModules) {
      programData.push(
        {
          id: 4445,
          title: `${this.props.appStore.selectedProduct?.numberOfModules} ${this.props.appStore.selectedProduct?.numberOfModules === 1 ? window.loc.strings.module : window.loc.strings.modules}`,
          icon: IconService.getIcon("Learning App", "#ffffff")
        },
      );
    }
    if (this.props.appStore.selectedProduct?.numberOfVideos) {
      programData.push(
        {
          id: 4545454,
          title: `${this.props.appStore.selectedProduct?.numberOfVideos} ${this.props.appStore.selectedProduct?.numberOfVideos === 1 ? window.loc.strings.video : window.loc.strings.videos}`,
          icon: IconService.getIcon("Video Clip", "#ffffff")
        }
      );
    }
    {
      this.props.appStore.selectedProductTeam?.info &&
        programData.push(
          {
            id: "Info",
            title: window.loc.strings.dates,
            icon: IconService.getIcon("Calendar", "#ffffff"),
            children: [
              <div
                style={{
                  padding: 20,
                  boxSizing: "border-box"
                }}
                dangerouslySetInnerHTML={{ __html: language !== "danish" && this.props.appStore.selectedProductTeam?.localizedInfo && this.props.appStore.selectedProductTeam.localizedInfo[language] ? this.props.appStore.selectedProductTeam.localizedInfo[language] : this.props.appStore.selectedProductTeam.info }}
              />
            ]
          }
        )
    }


    // Links
    const links = [];
    if (this.state.hasProductAccess && this.props.appStore.selectedProduct && this.props.appStore.selectedProduct.links && this.props.appStore.selectedProduct.links.length > 0) {
      this.props.appStore.selectedProduct.links.forEach((link: IProductLink, index) => {
        if (link.url.substr(link.url.length - 3) === "mp3") {
          links.push(
            <div
              key={`teamlinks${index}`}
              className="workbook"
              onClick={() => this.props.appStore.podcastToPlay = {
                "link": language !== "danish" && link.localizedUrl && link.localizedUrl[language] ? link.localizedUrl[language] : link.url,
                "title": language !== "danish" && link.localizedButtonTitle && link.localizedButtonTitle[language] ? link.localizedButtonTitle[language] : link.buttonTitle
              }}
            >
              <div className="text">{language !== "danish" && link.localizedButtonTitle && link.localizedButtonTitle[language] ? link.localizedButtonTitle[language] : link.buttonTitle}</div>
            </div>
          );
        } else {
          if (link.flowpaper || (link.openInSameWindow && link.url.slice(-3) === "pdf") && !this.props.appStore.isMobile && !this.props.appStore.isTablet) {
            links.push(
              <div
                key={`link_${index}`}
                className="workbook"
                onClick={() => this.props.appStore.pageEmbed = language !== "danish" && link.localizedUrl && link.localizedUrl[language] ? link.localizedUrl[language] : link.url}
              >
                <div className="text">{language !== "danish" && link.localizedButtonTitle && link.localizedButtonTitle[language] ? link.localizedButtonTitle[language] : link.buttonTitle}</div>
              </div>
            );
          } else {
            links.push(
              <a href={language !== "danish" && link.localizedUrl && link.localizedUrl[language] ? link.localizedUrl[language] : link.url} target={link.openInSameWindow ? "_self" : "_blank"}>
                <div
                  key={`link_${index}`}
                  className="workbook"
                >
                  <div className="text">{language !== "danish" && link.localizedButtonTitle && link.localizedButtonTitle[language] ? link.localizedButtonTitle[language] : link.buttonTitle}</div>
                </div>
              </a>
            );
          }
        }
      });
      programData.push(
        {
          id: "Links",
          title: "Links",
          children: [<div
            style={{
              padding: 20,
              boxSizing: "border-box"
            }}>
            {links}
          </div>]
        }
      )
    }

    // Team links
    const teamlinks = [];
    if (this.state.hasProductAccess && this.props.appStore.selectedProductTeam && this.props.appStore.selectedProductTeam.links && this.props.appStore.selectedProductTeam.links.length > 0) {
      this.props.appStore.selectedProductTeam.links.forEach((link: IProductLink, index) => {
        if (link.url.substr(link.url.length - 3) === "mp3") {
          teamlinks.push(
            <div
              key={`teamlinks${index}`}
              className="workbook"
              onClick={() => this.props.appStore.podcastToPlay = {
                "link": language !== "danish" && link.localizedUrl && link.localizedUrl[language] ? link.localizedUrl[language] : link.url
              }}
            >
              <div className="text">{language !== "danish" && link.localizedButtonTitle && link.localizedButtonTitle[language] ? link.localizedButtonTitle[language] : link.buttonTitle}</div>
            </div>
          );
        } else {
          if (link.openInSameWindow && !this.props.appStore.isMobile && !this.props.appStore.isTablet) {
            teamlinks.push(
              <div
                key={`teamlinks${index}`}
                className="workbook"
                onClick={() => this.props.appStore.pageEmbed = language !== "danish" && link.localizedUrl && link.localizedUrl[language] ? link.localizedUrl[language] : link.url}
              >
                <div className="text">{language !== "danish" && link.localizedButtonTitle && link.localizedButtonTitle[language] ? link.localizedButtonTitle[language] : link.buttonTitle}</div>
              </div>
            );
          } else {
            teamlinks.push(
              <a href={language !== "danish" && link.localizedUrl && link.localizedUrl[language] ? link.localizedUrl[language] : link.url} target="blank">
                <div
                  key={`teamlinks${index}`}
                  className="workbook"
                >
                  <div className="text">{language !== "danish" && link.localizedButtonTitle && link.localizedButtonTitle[language] ? link.localizedButtonTitle[language] : link.buttonTitle}</div>
                </div>
              </a>
            );
          }
        }
      });
      programData.push(
        {
          id: "teamLinks",
          title: "Team links",
          children: [<div
            style={{
              padding: 20,
              boxSizing: "border-box"
            }}>
            {teamlinks}
          </div>]
        }
      )
    }
    let titlePosition = -90;
    if (this.props.appStore.isMobile) {
      const title = document.getElementById("ProgramTitle");
      if (title) {
        console.log("title.innerWidth: " + title.clientWidth);
        console.log("screen.width: " + (screen.width - 40));
        if (title.clientWidth < (screen.width - 40)) {
          titlePosition = -70;
        }
      } else {
        setTimeout(() => {
          this.forceUpdate();
        }, 0)
      }
    }
    const image = language !== "danish" && this.props.appStore.selectedProduct?.localizedImage && this.props.appStore.selectedProduct?.localizedImage[language] ? this.props.appStore.selectedProduct?.localizedImage[language] : this.props.appStore.selectedProduct?.image;
    let banner = language !== "danish" && this.props.appStore.selectedProduct?.localizedBanner && this.props.appStore.selectedProduct?.localizedBanner[language] ? this.props.appStore.selectedProduct?.localizedBanner[language] : this.props.appStore.selectedProduct?.banner;
    if (!banner) {
      banner = image;
    }
    const title = language !== "danish" && this.props.appStore.selectedProduct?.localizedTitle && this.props.appStore.selectedProduct?.localizedTitle[language] ? this.props.appStore.selectedProduct?.localizedTitle[language] : this.props.appStore.selectedProduct?.title;
    const teaser = language !== "danish" && this.props.appStore.selectedProduct?.localizedTeaser && this.props.appStore.selectedProduct?.localizedTeaser[language] ? this.props.appStore.selectedProduct?.localizedTeaser[language] : this.props.appStore.selectedProduct?.teaser;
    return (
      <div
        className='program'
        style={{
          top: this.props.id != undefined && this.props.id !== "" ? (this.props.appStore.isMobile ? 0 : 50) : screen.height,
          height: this.props.appStore.podcastToPlay ? (this.props.appStore.isMobile ? "calc(100% - 50px)" : "calc(100% - 100px)") : (this.props.appStore.isMobile ? "100%" : "calc(100% - 50px)")
        }}
      >
        {this.props.appStore.isMobile ?
          <>
            <div
              className="programCover"
              style={{
                height: screen.availWidth / 4.5 * 3,
              }}
            >
              <div
                className="programCoverImage"
                style={{
                  backgroundImage: `url(${image})`
                }}
              />
            </div>
            <div className='programContentWrapper'>
              <div
                className='programContent'
                style={{
                  marginTop: screen.availWidth / 4.5 * 3
                }}
              >
                <Link to={this.props.appStore.selectedTab === 1 ? `${this.props.appStore.environment}` : `${this.props.appStore.environment}/programs`}>
                  <div
                    className='closeButton'
                    onClick={() => {
                      this.setState({ hasProductAccess: false, selectedPivot: "about" }, () => {
                        this.props.appStore.selectedProduct = undefined;
                        this.props.appStore.selectedProductTeam = undefined;
                        this.setState({ teams: undefined });
                        this.props.close();
                      })
                    }}
                  />
                </Link>
                {!this.state.hasProductAccess && this.props.appStore.selectedProduct?.link && this.props.appStore.user?.username !== "tester@mindjuice.dk" &&
                  <a href={this.props.appStore.selectedProduct?.link} target="_blank">
                    <div
                      className="cardBuyBtn"
                    >
                      {window.loc.strings.signUpNow.toUpperCase()}
                    </div>
                  </a>
                }
                <h1 id="ProgramTitle" >
                  {title}
                </h1>
                <Pivot
                  highlightColor="#b362fa"
                  textColor="#ffffff"
                  items={[
                    {
                      key: "about",
                      text: window.loc.strings.programPivotAbout,
                    },
                    {
                      key: "content",
                      text: window.loc.strings.programPivotContent,
                    },
                    {
                      key: "videos",
                      text: window.loc.strings.programPivotVideos,
                    }
                  ]}
                  style={{
                    fontSize: "14px",
                    textTransform: "uppercase"
                  }}
                  selectedKey={this.state.selectedPivot}
                  select={(selectedPivot: string) => {
                    this.setState({ selectedPivot });
                  }}
                />
                {this.state.selectedPivot === "about" &&
                  <>
                    <Accordion
                      isMobile={this.props.appStore.isMobile}
                      items={programData}
                      openInPopup={(url: string) => { }}
                      openInPageEmbed={(url: string) => { }}
                    />
                    <div
                      className='text'
                      dangerouslySetInnerHTML={{ __html: teaser }}
                    />
                  </>
                }
                {this.state.selectedPivot === "content" &&
                  <>
                    {informationDocuments.length > 0 &&
                      <>
                        <h2>{window.loc.strings.programPivotInfo}</h2>
                        <Accordion
                          isMobile={this.props.appStore.isMobile}
                          items={informationDocuments}
                          openInPopup={(url: string) => {
                            this.props.appStore.popup = url;
                          }}
                          openInPageEmbed={(url: string) => {
                            this.props.appStore.pageEmbed = url;
                          }}
                        />
                      </>
                    }
                    {productDocuments.length > 0 &&
                      <>
                        <h2>{window.loc.strings.programPivotContent}</h2>
                        <Accordion
                          isMobile={this.props.appStore.isMobile}
                          items={productDocuments}
                          openInPopup={(url: string) => {
                            this.props.appStore.popup = url;
                          }}
                          openInPageEmbed={(url: string) => {
                            this.props.appStore.pageEmbed = url;
                          }}
                        />
                      </>
                    }
                    {examDocuments.length > 0 &&
                      <>
                        <h2>{window.loc.strings.programPivotExam}</h2>
                        <Accordion
                          isMobile={this.props.appStore.isMobile}
                          items={examDocuments}
                          openInPopup={(url: string) => {
                            this.props.appStore.popup = url;
                          }}
                          openInPageEmbed={(url: string) => {
                            this.props.appStore.pageEmbed = url;
                          }}
                        />
                      </>
                    }
                  </>
                }
                {this.state.selectedPivot === "videos" &&
                  <div className='programVideos'>
                    {videos}
                  </div>
                }
              </div>
            </div>
          </>
          :
          <>
            <div
              className="programCover"
              style={{
                height: 300,
              }}
            >
              <div
                className="programCoverImage"
                style={{
                  backgroundImage: `url(${banner})`
                }}
              />
            </div>
            <div className='programContentWrapper'>
              <div
                className='programContent'
                style={{
                  marginTop: 300
                }}
              >
                <Link to={this.props.appStore.selectedTab === 1 ? `${this.props.appStore.environment}` : `${this.props.appStore.environment}/programs`}>
                  <div
                    className='closeButton'
                    onClick={() => {
                      this.setState({ hasProductAccess: false, selectedPivot: "about" }, () => {
                        this.props.appStore.selectedProduct = undefined;
                        this.props.appStore.selectedProductTeam = undefined;
                        this.setState({ teams: undefined });
                        this.props.close();
                      })
                    }}
                  />
                </Link>
                {this.props.appStore.editMode &&
                  <div>
                    <div
                      className='adminButton'
                      style={{
                        right: 62
                      }}
                      onClick={() => {
                        this.props.appStore.showProductAccessPanelForProduct = this.props.id;
                      }}
                    >
                      Adgang
                    </div>
                    <div
                      className='adminButton'
                      style={{
                        right: 165
                      }}
                      onClick={() => {
                        this.props.appStore.productToEdit = this.props.appStore.selectedProduct;
                      }}
                    >
                      Rediger produkt
                    </div>
                    <div
                      className='adminButton'
                      style={{
                        right: 325
                      }}
                      onClick={() => {
                        this.props.appStore.showTeamPanelForProduct = this.props.id;
                      }}
                    >
                      Tilføj hold
                    </div>
                    {this.state.teams &&
                      <div
                        className='adminButton'
                        style={{
                          right: 440
                        }}
                        onClick={() => {
                          this.props.appStore.teamToEdit = this.props.appStore.selectedProductTeam;
                        }}
                      >
                        Rediger hold
                      </div>
                    }
                    {this.state.teams &&
                      <select
                        name="cars"
                        id="cars"
                        placeholder="Vælg et MindPaper"
                        value={this.props.appStore.selectedProductTeam?.objectId}
                        style={{
                          right: 585
                        }}
                        onChange={(selectedOption: any) => {
                          this.state.teams.forEach((team: ITeam) => {
                            if (selectedOption.target.value === team.objectId) {
                              this.props.appStore.selectedProductTeam = team;
                              this.forceUpdate();
                            }
                          });
                        }}
                      >
                        {teamOptions}
                      </select>
                    }
                  </div>
                }
                {!this.state.hasProductAccess && this.props.appStore.selectedProduct?.link && !this.props.appStore.editMode && this.props.appStore.user?.username !== "tester@mindjuice.dk" &&
                  <a href={this.props.appStore.selectedProduct?.link} target="_blank">
                    <div
                      className="cardBuyBtn"
                    >
                      {window.loc.strings.signUpNow.toUpperCase()}
                    </div>
                  </a>
                }

                <h1>{title}</h1>
                <div className='programContentAccordions'>
                  <h2>{window.loc.strings.programPivotAbout}</h2>
                  <Accordion
                    isMobile={this.props.appStore.isMobile}
                    items={programData}
                    openInPopup={(url: string) => { }}
                    openInPageEmbed={(url: string) => { }}
                  />
                  <div
                    className='text'
                    dangerouslySetInnerHTML={{ __html: teaser }}
                  />
                  {informationDocuments.length > 0 &&
                    <>
                      <h2>{window.loc.strings.programPivotInfo}</h2>
                      <Accordion
                        isMobile={this.props.appStore.isMobile}
                        items={informationDocuments}
                        openInPopup={(url: string) => {
                          this.props.appStore.popup = url;
                        }}
                        openInPageEmbed={(url: string) => {
                          this.props.appStore.pageEmbed = url;
                        }}
                      />
                    </>
                  }
                  <h2>{window.loc.strings.programPivotContent}</h2>
                  <Accordion
                    isMobile={this.props.appStore.isMobile}
                    items={productDocuments}
                    openInPopup={(url: string) => {
                      this.props.appStore.popup = url;
                    }}
                    openInPageEmbed={(url: string) => {
                      this.props.appStore.pageEmbed = url;
                    }}
                  />
                  {examDocuments.length > 0 &&
                    <>
                      <h2>{window.loc.strings.programPivotExam}</h2>
                      <Accordion
                        isMobile={this.props.appStore.isMobile}
                        items={examDocuments}
                        openInPopup={(url: string) => {
                          this.props.appStore.popup = url;
                        }}
                        openInPageEmbed={(url: string) => {
                          this.props.appStore.pageEmbed = url;
                        }}
                      />
                    </>
                  }
                </div>
                <div className='programContentVideos'>
                  <div className='programVideos'>
                    {videos}
                  </div>
                </div>
              </div>
            </div >
          </>
        }
      </div >
    );
  }
}