import * as React from 'react';
import './EnneagrammyInfoPage.css';
import { inject, observer } from 'mobx-react';
import { AppStore } from '../../../../stores';
import { Link } from 'react-router-dom';
import ProductService from '../../../../services/ProductService';

export interface IProps {
  open: boolean;
  close: () => void;
  appStore?: AppStore;
}

export interface IState {
  hasAccess: boolean;
}

@inject('appStore')
@observer
export default class EnneagrammyInfoPage extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      hasAccess: false
    }
  }

  public componentWillReceiveProps(props: IProps): void {
    if (this.props.open !== props.open && props.open && this.props.appStore.user) {
      ProductService.productAccess(this.props.appStore.showEnneagrammy.productId, this.props.appStore.user.username).then((enneagrammy: any) => {
        if (enneagrammy?.results?.length > 0) {
          this.setState({ hasAccess: true });
        } else {
          ProductService.productAccess("17827", this.props.appStore.user.username).then((doYouKnowTheType: any) => {
            if (doYouKnowTheType?.results?.length > 0) {
              this.setState({ hasAccess: true });
            } else {
              // User does not have access from MongoDB and will check Billwerk
              this.props.appStore.getCustomerHandle().then((customerHandle: string) => {
                if (customerHandle) {
                  ProductService.getCustomerInvoice(customerHandle).then((invoice) => {
                    if (invoice?.content?.length > 0 && invoice.content[0].order_lines?.length > 0) {
                      invoice.content[0].order_lines.forEach((orderLine) => {
                        if ("Kender du typen?" === orderLine?.ordertext) {
                          this.setState({ hasAccess: true });
                        } else {
                          this.setState({ hasAccess: false });
                        }
                      });
                    }
                  })
                } else {
                  this.setState({ hasAccess: false });
                }
              });
            }
          });
        }
      });
    }
  }

  render() {
    return (
      <div
        className='enneagrammyInfoPage'
        style={{
          top: this.props.appStore.showEnneagrammy ? (this.props.appStore.isMobile ? 0 : 50) : screen.height,
          height: this.props.appStore.podcastToPlay ? (this.props.appStore.isMobile ? "calc(100% - 101px)" : "calc(100% - 151px)") : (this.props.appStore.isMobile ? "100%" : "calc(100% - 50px)")
        }}
      >
        <>
          <div
            className="enneagrammyInfoPageCover"
            style={{
              height: 200,
            }}
          >
            <div
              className="enneagrammyInfoPageCoverImage"
              style={{
                backgroundImage: `url(https://dojo.mindjuice.com/images/enneagrammy-cover.jpg)`
              }}
            />
          </div>
          <div className='enneagrammyInfoPageContentWrapper'>
            <div
              className='enneagrammyInfoPageContent'
              style={{
                marginTop: 200
              }}
            >
              <div
                className='closeButton'
                onClick={() => {
                  this.props.appStore.showEnneagrammy = undefined;
                  this.props.appStore.typeTestChangeName = false;
                  history.back();
                }}
              />
              {this.props.appStore.editMode &&
                <div>
                  <div
                    className='adminButton'
                    style={{
                      right: 62
                    }}
                    onClick={() => {
                      this.props.appStore.productToEdit = this.props.appStore.showEnneagrammy;
                    }}
                  >
                    Rediger
                  </div>
                  <div
                    className='adminButton'
                    style={{
                      right: 165
                    }}
                    onClick={() => {
                      this.props.appStore.showProductAccessPanelForProduct = this.props.appStore.showEnneagrammy.productId;
                    }}
                  >
                    Adgang
                  </div>
                </div>
              }
              <h1>{this.props.appStore.showEnneagrammy?.title}</h1>
              {!this.state.hasAccess && this.props.appStore.showEnneagrammy?.link &&
                <a href={this.props.appStore.showEnneagrammy?.link} target="_blank">
                  <div
                    className="cardBuyBtn"
                  >
                    {window.loc.strings.signUpNow.toUpperCase()}
                  </div>
                </a>
              }
              <div className="enneagrammyInfoPageBox">
                <h2>{window.loc.strings.testIntroHeadline}</h2>
                <div
                  className="enneagrammyInfoPageTextContainer"
                  dangerouslySetInnerHTML={{ __html: window.loc.strings.frontpageAboutText }}
                />
                {this.state.hasAccess &&
                  <div className="enneagrammyYourTypeBtns">
                    {this.props.appStore.testResults?.length > 0 &&
                      <Link
                        to={`${this.props.appStore.environment}/tests/19067/test`}
                      >
                        <div
                          className="enneagrammyStartTestBtn"
                          onClick={() => {
                            this.props.appStore.startTest();
                            this.props.appStore.testProgression = "Test";
                            this.props.appStore.showEnneagrammyTest = true;
                          }}
                        >
                          {window.loc.strings.testIntroStartBtn.toUpperCase()}
                        </div>
                      </Link>
                    }
                    <Link
                      to={`${this.props.appStore.environment}/tests/19067/results`}
                    >
                      <div
                        className="enneagrammyYourTypeBtn"
                        onClick={() => {
                          this.props.appStore.selectedTypeInfoTab = "result";
                          this.props.appStore.showEnneagrammyTestResults = true;
                          this.props.appStore.selectedTestResult = this.props.appStore.myResult;
                        }}
                      >
                        {window.loc.strings.tabBarResults.toUpperCase()}
                      </div>
                    </Link>
                  </div>
                }
              </div>
              <div className='enneagrammyInfoPageVideos'>
                <h2>Introvideo</h2>
                <iframe
                  src={`https://player.vimeo.com/video/${window.loc.strings.enneagramVideo}`}
                  width="600"
                  height={600 / 16 * 9}
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                  style={{
                    backgroundColor: "#064859"
                  }}
                />
              </div>
              {this.props.appStore.user?.userType === "admin" &&
                <div className="enneagrammyInfoPageGroups">
                  <h2>Grupper</h2>
                  <div className="enneagrammyStartTestBtns">
                    <div
                      className="enneagrammyCreateGroupBtn"
                      onClick={() => {
                        this.props.appStore.groupToEdit = {
                          admins: [this.props.appStore.user?.username]
                        };
                      }}
                    >
                      {"OPRET GRUPPE"}
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </>
      </div >
    );
  }
}