import * as React from 'react';
import './LogoBar.css';
import { inject, observer } from 'mobx-react';
import { AppStore } from '../../stores';
import NativeService from '../../services/NativeService';
import { Link } from 'react-router-dom';

export interface IProps {
  appStore?: AppStore;
}

export interface IState {
}

@inject('appStore')
@observer
export default class LogoBar extends React.Component<IProps, IState> {

  constructor(props,) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <div className='logoBar'>
        <Link
          to={`${this.props.appStore.environment}/`}
          key={"programs"}
        >
          <img
            src={this.props.appStore.isMobile ? "https://dojo.mindjuice.com/images/mindjuice-university-logo-mobile.png" : "https://dojo.mindjuice.com/images/mindjuice-university-logo.png"}
            height={50}
            width={this.props.appStore.isMobile ? 250 : 300}
            style={{
              marginLeft: this.props.appStore.isMobile ? 5 : "calc(50% - 150px)",
              cursor: "pointer"
            }}
          />
        </Link>
        <div
          className="settingsButton"
          style={{
            backgroundImage: `url(https://img.icons8.com/fluency-systems-regular/48/ffffff/settings.png)`
          }}
          onClick={() => this.props.appStore.showSettings = true}
        />
        <div
          className="profileButton"
          style={{
            backgroundImage: `url(https://dojo.mindjuice.com/images/user-icon.png)`,
            opacity: this.props.appStore.user ? 1 : 0.5
          }}
          onClick={() => this.props.appStore.user ? this.props.appStore.showProfile = true : []}
        />
        <div
          className="menuNotification"
          style={{
            opacity: this.props.appStore.user ? 1 : 0.5
          }}
          onClick={() => this.props.appStore.user ? this.props.appStore.showNotification = true : {}}
        >
          {/* <div
            className="menuNotificationDot"
            style={{
              opacity: (this.props.appStore.unreadNotifications && this.props.appStore.unreadNotifications > 0) ? 1 : 0
            }}
          >
            <div
              className="menuNotificationDotNumber"
            >
              {this.props.appStore.unreadNotifications}
            </div>
          </div> */}
        </div>
      </div>
    )
  }
}