import * as React from 'react';
import { IModuleDocument } from '../../../interfaces/IModuleDocument';
import AdminService from '../../../services/AdminService';
import DocumentPicker from '../documentPicker/DocumentPicker';
import { Icon } from '../icon/Icon';
import './AdminModuleDocument.css';

export interface IProps {
  id: string;
  disableMoveUp: boolean;
  disableMoveDown: boolean;
  isMobile: boolean;
  selectedLanguage?: string;
  onDelete: () => void;
  onMoveUp: () => void;
  onMoveDown: () => void;
  onUpdate: (document: IModuleDocument) => void;
}

export interface IState {
  editMode: boolean;
  document: IModuleDocument;
}

export default class AdminModuleDocument extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      document: undefined
    }
    AdminService.getFile(props.id).then((document: IModuleDocument) => {
      this.setState({ document });
    });
  }

  public componentWillReceiveProps(props: IProps): void {
    if (props.id && props.id !== this.props.id) {
      AdminService.getFile(props.id).then((document: IModuleDocument) => {
        this.setState({ document });
      });
    }
  }

  render() {
    return this.state.editMode && this.state.document ? (
      <DocumentPicker
        document={this.state.document}
        onChange={(document: IModuleDocument) => {
          this.setState({ editMode: false, document });
        }}
        disableMoveDown={this.props.disableMoveDown}
        disableMoveUp={this.props.disableMoveUp}
        isMobile={this.props.isMobile}
        selectedLanguage={this.props.selectedLanguage}
        onDelete={() => {
          this.props.onDelete();
        }}
        onMoveUp={() => {
          this.props.onMoveUp();
        }}
        onMoveDown={() => {
          this.props.onMoveDown();
        }}
      />
    ) : (
      (this.state.document ?
        <div
          key={`documentLink_${this.state.document.objectId}`}
          className='moduleDocument'
        >
          {this.state.document.icon &&
            <Icon
              title={this.props.selectedLanguage === "danish" ? this.state.document.icon : (this.state.document.localizedIcon && this.state.document.localizedIcon[this.props.selectedLanguage] ? this.state.document.localizedIcon[this.props.selectedLanguage] :  this.state.document.icon)}
              size={22}
              color={"#272727"}
              style={{
                float: "left",
                marginTop: 4
              }}
            />
          }
          <div
            className='moduleDocumentText'
            style={{
              width: this.state.document.icon ? (this.props.isMobile ? "calc(100% - 240px)" : "calc(100% - 345px)") : (this.props.isMobile ? "calc(100% - 240px)" : "calc(100% - 335px)")
            }}
          >
            {this.props.selectedLanguage === "danish" ? this.state.document.documentTitle : (this.state.document.localizedDocumentTitle && this.state.document.localizedDocumentTitle[this.props.selectedLanguage] ? this.state.document.localizedDocumentTitle[this.props.selectedLanguage] : this.state.document.documentTitle)}
          </div>
          <button
            style={{
              marginTop: 0,
              float: "right",
              backgroundColor: "red"
            }}
            onClick={() => {
              this.props.onDelete();
            }}
          >
            <Icon
              title={"Trash"}
              size={18}
              color={"#ffffff"}
              style={{
              }}
              onClick={() => { }}
            />
          </button>
          <button
            style={{
              marginTop: 0,
              float: "right",
            }}
            onClick={() => {
              this.setState({ editMode: true });
            }}
          >
            <Icon
              title={"Edit"}
              size={18}
              color={"#ffffff"}
              style={{
              }}
              onClick={() => { }}
            />
          </button>
          <button
            style={{
              marginTop: 0,
              float: "right",
              opacity: this.props.disableMoveDown ? 0.5 : 1
            }}
            disabled={this.props.disableMoveDown}
            onClick={() => {
              this.props.onMoveDown();
            }}
          >
            <Icon
              title={"ArrowDown"}
              size={18}
              color={"#ffffff"}
              style={{
              }}
              onClick={() => { }}
            />
          </button>
          <button
            style={{
              marginTop: 0,
              float: "right",
              opacity: this.props.disableMoveUp ? 0.5 : 1
            }}
            disabled={this.props.disableMoveUp}
            onClick={() => {
              this.props.onMoveUp();
            }}
          >
            <Icon
              title={"ArrowUp"}
              size={18}
              color={"#ffffff"}
              style={{
              }}
              onClick={() => { }}
            />
          </button>
        </div>
        :
        <div>Loading...</div>)
    );
  }
}