import * as React from 'react';
import './UserSearchPopup.css';
import { AppStore } from '../../stores';
import { inject, observer } from 'mobx-react';

export interface IProps {
  appStore?: AppStore;
  users: JSX.Element[];
  close: () => void;
}

export interface IState {
}

@inject('appStore')
@observer
export default class UserSearchPopup extends React.Component<IProps, IState> {

  render() {
    const pushPopupBox = document.getElementById("UserSearchPopupBox");
    let marginTop;
    if (pushPopupBox) {
      marginTop = `calc(50% - ${pushPopupBox.clientHeight / 2}px`;
    } else {
      this.forceUpdate();
    }
    return (
      <div className="userSearchPopup">
        <div
          className="userSearchPopupBackground"
          onClick={() => {
            this.props.close();
          }}
        />
        <div
          id="UserSearchPopupBox"
          className="userSearchPopupBox"
          style={{
            top: marginTop
          }}
        >
          <h3>Vælg en modtager</h3>
          {this.props.users}
          <div className="userSearchPopupCloseButton"
            onClick={() => {
              this.props.close();
            }}
          >
            Luk
          </div>
        </div>
      </div>
    );
  }

}