import { INotification } from "../interfaces/INotification";
import { IReadNotification } from "../interfaces/IReadNotification";

export default class NotificationService {

  public static getNotifications(): Promise<INotification[]> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/Notifications/`, {
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          return response.json();
        }
      }).then(response => {
        resolve(response.results?.reverse());
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }

  public static getNotificationsForUser(user: string): Promise<INotification[]> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/Notifications/?where={"$or":[{"pushReceivers":{"$in":["${user}"]}},{"sendToAll":true}]}`, {
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          return response.json();
        }
      }).then(response => {
        const resultsToReturn = [];
        if (response.results?.reverse()?.length > 0) {
          response.results?.reverse().forEach(element => {
            if (element?.expirationeDate == undefined || (element?.expirationeDate && new Date(element?.expirationeDate) >= new Date())) {
              resultsToReturn.push(element);
            }
          });
        }
        resolve(resultsToReturn);
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }

  public static createNotification(notification: INotification): Promise<void> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/Notifications/`, {
        method: "POST",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        },
        body: JSON.stringify({
          title: notification.title,
          text: notification.text,
          link: notification.link,
          linkText: notification.linkText,
          sendToAll: notification.sendToAll,
          receivers: notification.receivers,
          pushReceivers: notification.pushReceivers,
          releaseDate: notification.releaseDate ? notification.releaseDate : new Date(),
          expirationeDate: notification.expirationeDate

        })
      }).then((response) => {
        if (!response.ok) {
          resolve();
        } else {
          resolve();
        }
      }).catch((error) => {
        console.log(error);
        resolve();
      });
    });
  }

  public static updateNotification(notification: INotification): Promise<void> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/Notifications/${notification.objectId}`, {
        method: "PUT",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        },
        body: JSON.stringify(
          {
            title: notification.title,
            text: notification.text,
            link: notification.link,
            linkText: notification.linkText,
            sendToAll: notification.sendToAll,
            receivers: notification.receivers,
            pushReceivers: notification.pushReceivers,
            releaseDate: notification.releaseDate ? notification.releaseDate : new Date(),
            expirationeDate: notification.expirationeDate
          }
        )
      }).then((response) => {
        if (!response.ok) {
          resolve();
        } else {
          resolve();
        }
      }).catch((error) => {
        console.log(error);
        resolve();
      });
    });
  }

  public static deleteNotification(id: string): Promise<void> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/Notifications/${id}`, {
        method: "DELETE",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve();
        } else {
          resolve();
        }
      }).catch((error) => {
        console.log(error);
        resolve();
      });
    });
  }


  /* Read notifications */

  public static getReadNotifications(userId: string): Promise<IReadNotification> {
    return new Promise((resolve) => {
      const url = `https://mindjuice.herokuapp.com/parse/classes/ReadNotifications/?where={\"userId\":\"${userId}\"}`;
      fetch(
        url, {
        method: "GET",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        },
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          return response.json();
        }
      }).then(response => {
        resolve(response && response.results && response.results.length > 0 ? response.results[0] : undefined);
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }

  public static createReadNotifications(readNotications: IReadNotification): Promise<void> {
    return new Promise((resolve) => {
      const url = `https://mindjuice.herokuapp.com/parse/classes/ReadNotifications/`;
      fetch(
        url, {
        method: "POST",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        },
        body: JSON.stringify(readNotications)
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          resolve()
        }
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }

  public static updateReadNotifications(readNotications: IReadNotification): Promise<void> {
    return new Promise((resolve) => {
      const url = `https://mindjuice.herokuapp.com/parse/classes/ReadNotifications/${readNotications.objectId}`;
      fetch(
        url, {
        method: "PUT",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        },
        body: JSON.stringify({
          notificationIds: readNotications.notificationIds
        })
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          resolve()
        }
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }
}